import React, { Component } from 'react';
import Imgloading from './itemloading';
import { PhotoView } from 'react-photo-view';
import handClick from '../../../assets/tap.png'
import handClick2 from '../../../assets/tap2.png'

class Large extends Component {

    data = this.props.Data;
    language = this.props.Language;

    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
           return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    addItemToCart = () => {
        this.props.addCartItem(this.data)
    }

    render() {
        return (
            <div className="item" > 
                <PhotoView key={this.data.id} src={this.data.image} overlay={this.data}>
                    <div className="image">
                        <Imgloading Img={this.data.image} />
                    </div>
                </PhotoView>
                {/* <span className="item-order">{this.data.order??0}</span> */}
                <h5 style={this.props.Language == "en"? {letterSpacing:'1px', fontFamily:'Farro'}: {fontFamily:'Noto Kufi Arabic'}}>
                    {
                        this.props.Language == "en" ? this.data.name_eng
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    }          
                </h5>
                <div className="price-addToCart">
                <div className='price'>
                                <span style={this.props.Language == 'en' ? {fontFamily: 'Farro'}: {fontFamily: 'Noto Kufi Arabic'}} > 
                                    {this.data.price}
                                    <span>{(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'} 
                                    </span> 
                                    {
                                        this.data.prices.length > 1? <b className='multi'> ( 
                                            {
                                                this.props.Language == "en" ? 'Multi Prices'
                                                : this.props.Language == "krd" ? 'چەندین بها'
                                                : 'أسعار متعددة'
                                            } 
                                             {this.props.Language === 'en' ? (
                                                <img src={handClick} alt="Hand Click" className='handClick-icon' />
                                            ) : (
                                                <img src={handClick2} alt="handClick2" className='handClick-icon2' />
                                            )}
                                        
                                             ) </b> : ''
                                    }

                                     
                                        
                                        
                                    </span>
                                     
                                     
                                
                            </div>
                    <div className='addToCart'>
                        <button className='addToCartButton' onClick={()=> this.addItemToCart()}>
                            <i className="fa-solid fa-plus"></i>
                        </button>
                        {/* <div className='action'>
                            <i className="fa-solid fa-plus"></i>
                            0
                            <i className="fa-solid fa-minus"></i>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

export default Large;