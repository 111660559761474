import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import topCategImg from '../../assets/topCategImg.png';

class Item extends Component {

    data = this.props.Data;
    language = this.props.Language;

    state = {
        isLoading: true
    };
    
    componentDidMount() {
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 2000);
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>
                {
                    isLoading ? 
                    <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="black" >
                        <Skeleton height={200} containerClassName="flex-1"
                         customHighlightBackground="linear-gradient(90deg, #456 40%, #852 50%, #13A 60%)" />
                    </SkeletonTheme>
                    : 
                    <Link to={"/menu/" + this.data['id']}>
                    <div className="category-item" style={ { backgroundImage :`url(${this.data.cover})`, backgroundSize: 'cover', backgroundPosition: 'center' , filter:"brightness(0.9)", width: (this.props.Index+1) % 3 === 0 ? '' : ''} }>
                    <img src={topCategImg} alt="" className='topCategImg'/>
                    </div>
                    <Link to={"/menu/" + this.data['id']}>
                            <h2 style={this.props.Language == "en" ? {fontFamily: 'Farro'} : {fontFamily: 'Noto Kufi Arabic'}}> 
                                {
                                    this.props.Language == "en" ? this.data['name_eng']
                                    : this.props.Language == "krd" ? this.data['name_krd']
                                    : this.data['name_ar']
                                }
                            </h2>
                        </Link>
                    </Link>
                }
                
            </>
        );
    }
}

export default Item;