import React, { Component } from 'react';
import Imgloading from './itemloading';
import { PhotoView } from 'react-photo-view';
import handClick from '../../../assets/tap.png'

class Two extends Component {

    data = this.props.Data;
    language = this.props.Language;

    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    render() {
        return ( 
            <div className="item" > 

                <PhotoView key={this.data.id} src={this.data.image} overlay={this.data}>
                    <div className="image">
                        <Imgloading Img={this.data.image} />
                    </div>
                </PhotoView>
                {/* <span className="item-order">{this.data.order??0}</span> */}
                <h5 style={this.props.Language == "en"? {letterSpacing:'1px'}: {}}>
                    {
                        this.props.Language == "en" ? this.data.name_eng
                        : this.props.Language == "krd" ? this.data.name_krd
                        : this.data.name_ar
                    }          
                </h5>
                <div className='price'>
                                <span> 
                                    {this.data.price} {(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'} 

                                    {
                                        this.data.prices.length > 1? <b> ( 
                                            {
                                                this.props.Language == "en" ? 'Multi Prices'
                                                : this.props.Language == "krd" ? 'چەندین بها'
                                                
                                                : 'أسعار متعددة'
                                            }  ) </b> : ''
                                    }
                                </span>
                                
                            </div>
                    
            </div>
        );
    }
}

export default Two;