import React, { Component } from 'react'
import Imgloading from './list/itemloading';
import backgroundImage from '../../assets/background.png';
import walletLogo from '../../assets/Wallet.png';
import logo from '../../assets/logo.png';


export default class Cart extends Component {

    handleCartClose = close => {
        this.props.HandleCartClose(close);
    }

  render() {
    return (
        <div className={`cart${this.props.Show?' cart-show': ''}`}>
             <div className=""><img className='backgroundImage1' src={backgroundImage} alt="" /></div>
        <div className='cart-head'>
            <div className='cart-head-close' style={{zIndex: 2}}>
                <i className="fa-solid fa-xmark" onClick={()=> this.handleCartClose(false)}></i>
            </div>
        </div>
        <div style={{textAlign: 'center'}}>
            <img src={logo} alt="" className='head-logo ani-stb' />
        </div>
        <div className='cart-body ani-fade-in'>

            {
                this.props.Cart.length?
                        <>
                            {
                                this.props.Cart.map( (item, index) => {
                                    return(
                                        <>
                                        <div key={index} className='cart-item'>
                                                <div className='img'>
                                                    <Imgloading Img={item.info.image} />
                                                </div>
                                                
                                                <div className='c-f-all'>
                                                        <div className='info'>
                                                            <h4>
                                                                {
                                                                    this.props.Language == "en" ? item.info.name_eng
                                                                    : this.props.Language == "krd" ? item.info.name_krd
                                                                    : item.info.name_ar
                                                                }
                                                            </h4>
                                                            <span>{ item.info.price.toLocaleString() }</span>
                                                        </div>
                                                    
                                                        <div className='action'>
                                                            <i className="fa-solid fa-plus" onClick={() => this.props.updateCartItem(index, '+')}></i>
                                                            { item.qty.toLocaleString() }
                                                            <i className="fa-solid fa-minus" onClick={() => this.props.updateCartItem(index, '-')}></i>
                                                        </div>
                                                </div>
                                                   
                                                <div className="cart-bottom-line"></div>
                                        </div>

                                       
                                        </>
                                    )
                                })
                            }
                        </>
                        :
                        <p style={this.props.Language == 'en' ? {direction: 'ltr'}:{direction: 'rtl',fontFamily: 'Noto Kufi Arabic'}}> 
                            {this.props.Language === "en" ? 'There are no items in your list.' :
                            this.props.Language === "krd" ? 'لیستا تە یا ڤالایە.' :
                            'لا توجد عناصر في قائمتك.'}
                        </p>

                    
            }
        </div>
           
        <div className="cart-footer ani-btt-f">
        <div className="summary-of-price">
            <p style={this.props.Language == 'en' ? {direction: 'ltr'}:{direction: 'rtl',fontFamily: 'Noto Kufi Arabic'}}> 
                            {this.props.Language === "en" ? 'Payment Summary' :
                            this.props.Language === "krd" ? 'پوختێ پارەدانێ' :
                            'ملخص الدفع'}
                        </p>
                <div className='info'>
                <div className='info-content-container' style={this.props.Language == 'en' ? {direction: 'ltr'}:{direction: 'rtl',fontFamily: 'Noto Kufi Arabic'}}>
                    <div className="item-text"> 
                            {this.props.Language === "en" ? 'Items' :
                            this.props.Language === "krd" ? 'مادە' :
                            'مادة'}
                    </div>
                    <div className="item-quantity">
                    ({this.props.SumQty.toLocaleString()})
                    </div>
                </div>
                <div className='info-content-container' style={this.props.Language == 'en' ? {direction: 'ltr'}:{direction: 'rtl',fontFamily: 'Noto Kufi Arabic'}}>
                <div className="item-text"> 
                            {this.props.Language === "en" ? 'Totals' :
                            this.props.Language === "krd" ? 'سەرجەم' :
                            'مجموع'}
                            
                    </div>
                    <div className="item-quantity">
                    {this.props.SumPrice.toLocaleString()}
                    <span className='currency'>{(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'}</span> 
                    </div>
                </div>

                
                
            </div>

            </div>
          
        </div>
    </div>
    )
  }
}
