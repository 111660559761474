import React, { Component } from 'react';
import Rating from '@mui/material/Rating';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import logo from '../assets/logo.png'
import Cart from './menu/cart';

export default class Feedback extends Component {
    state = {
        staff: 0,
        service: 0,
        clean: 0,
        comment: '',
        contact: '',
        sendFeedback: false,
        redirect: false,
        cart: false,
    }
   

    constructor(props) {
        super(props);
        this.sendFeedback = this.sendFeedback.bind(this);
        this.state = {
            Feedback: true, 
        };
    }
    handleFeedbackClose = (close) => {
        this.setState({ redirect: true });
    }

    handleLanguageChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    layoutDirection() {
        const feedbackElement = document.getElementById("feedbackId");
        if (feedbackElement) {
            feedbackElement.style.direction = this.props.Language !== "en" ? "rtl" : "ltr";
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    
    
    
    async sendFeedback() {
        try {
            const { data } = await axios.post('https://manage.caviarmenu.com/api/feedback', {
                username: this.props.Settings.username,
                service: this.state.service,
                staff: this.state.staff,
                clean: this.state.clean,
                comment: this.state.comment,
                contact: this.state.contact,
            });

            if (data.status) {
                this.setState({ sendFeedback: true });
                setTimeout(() => {
                    this.setState({ redirect: true });
                }, 1500);
            }
        } catch (error) {
            console.error("Error sending feedback", error);
            // Handle the error appropriately (e.g., show a message to the user)
        }
    }

    
    goBack = () => {
        window.history.back();
      };


    

    render() {
        if (this.state.redirect) {
            return <Navigate to="/home" replace={true} />;
        }

        return (
            <div>
                 <div className="head feedback-head ani-fade-in" style={this.headCover} >
                
                    <div className="head-right">
                      
                    </div>

                    <div className="head-left">

                        

                    <div className='cart-head-close'>
                    <i className="fa-solid fa-xmark" onClick={this.goBack}></i>
                    </div>
                    </div>
                
                    
                </div>
                <Link to={"/home"} className='d-flex '>
                <img src={logo} alt="" className='head-logo ani-fade-in'/>
                </Link>
                <div className='lang ani-fade-in'>
                        <div role='button' className={this.props.Language == "krd"? 'active': 'button'} onClick={() => this.handleLanguageChange('krd')}
                             style={{fontFamily: 'Noto Kufi Arabic'}}> کوردی </div>
                        <div role='button' className={this.props.Language == "ar"? 'active': 'button'} onClick={() => this.handleLanguageChange('ar')}
                             style={{fontFamily: 'Noto Kufi Arabic'}}> العربیة </div>
                        <div role='button' className={this.props.Language == "en"? 'active': 'button'} onClick={() => this.handleLanguageChange('en')} 
                        style={{ fontWeight: 700 }}> English </div>
                    </div>

                {this.state.sendFeedback ? (
                    <div className='feedback-success-message'  
                    style={this.props.Language !== 'en' ?{fontFamily: 'Noto Kufi Arabic'}: {}}>
                        {this.props.Language === "en" ? 'Thank you for your feedback! We appreciate your input and will use it to improve.' :
                            this.props.Language === "krd" ? 'سوپاس بۆ بۆچوون و تێبینیێن هەوە، بلند دنرخینین و ژ بۆ باشتربوون و پێشڤەچوونێ دێ بکارهینین.' :
                            'نشكرك على ملاحظاتك! نقدر مشاركتك وسنستخدمها لتحسين الأمور.'}
                    </div>
                ) : (
                    <div className='feedback ani-fade-in' id='feedbackId'
                    style={this.props.Language !== 'en' ?{fontFamily: 'Noto Kufi Arabic'}: {}}>
                        

                        {['staff', 'service', 'clean'].map((type, index) => (
                            <div className='feedback-item' key={index}>
                                <div className='title'>
                                    {type === 'staff' ? (this.props.Language === "en" ? 'Staff Rating:' :
                                        this.props.Language === "krd" ? 'هەڵسەنگاندنا کارمەدان' : 'تقییم الموظفین:') :
                                        type === 'service' ? (this.props.Language === "en" ? 'Service Rating:' :
                                            this.props.Language === "krd" ? 'هەڵسەنگاندنا خزمەتگۆزاریێ' : 'تقییم الخدمة:') :
                                            (this.props.Language === "en" ? 'Clean Rating:' :
                                                this.props.Language === "krd" ? 'هەڵسەنگاندنا پاقژیێ' : 'تقییم النظافة:')}
                                </div>
                                <div className='rating'>
                                    <Rating
                                            name="simple-controlled"
                                            value={this.state.type}
                                            onChange={(event, newValue) => {
                                                this.setState({ [type]: newValue })
                                            }}
                                        />
                                        
                                </div>
                            </div>
                        ))}

                        <div className='feedback-item'>
                            <div className='title'>
                                {this.props.Language === "en" ? 'Additional Feedback' :
                                    this.props.Language === "krd" ? 'تێبینی یان پێشنیار' : 'ملاحظات إضافية'}
                            </div>
                            <p className='text-fed-a'
                              style={this.props.Language === "en" ? { marginRight: 'auto' } : { marginLeft: 'auto' }}>
                                {
                                    this.props.Language === "en" ? 'What else would you like us to know?' :
                                    this.props.Language === "krd" ? 'تێبینی یان پێشنیارەکا دی تە هەیە؟' :
                                    'ما الذي تود إخبارنا به أيضًا؟'
                                }
                            </p>
                            <div className='Comment'>
                                <textarea name='comment' placeholder={
                                    this.props.Language === "en" ? 'My  Feedback!' :
                                        this.props.Language === "krd" ? 'فیدباک!' :
                                        'تقييمي!'
                                } onChange={this.handleChange}></textarea>
                            </div>
                        </div>

                        <div className='feedback-item'>
                              <p className='text-fed-a'
                               style={this.props.Language === "en" ? { marginRight: 'auto' } : { marginLeft: 'auto' }}>
                                {this.props.Language === "en" ? 'Phone / Email (optional)' :
                                    this.props.Language === "krd" ? 'ژمارا تلوفوونێ / ئێمێل (ئەگەر پێدڤیبکەت)' : 'رقم الهاتف / البريد الإلكتروني(اختياري)'}
                            </p>
                            <div className='field'>
                                <input type="text" name='contact' placeholder={
                                    this.props.Language === "en" ? 'Phone / Email:' :
                                        this.props.Language === "krd" ? 'ژمارا تلوفوونێ / ئێمێل:' : 'رقم الهاتف / البريد الإلكتروني:'
                                } onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className='feedback-item'>
                            <button onClick={this.sendFeedback}  
                            style={this.props.Language !== 'en' ?{fontFamily: 'Noto Kufi Arabic'}: {}}>
                                {this.props.Language === "en" ? 'Send' :
                                    this.props.Language === "krd" ? 'هنارتن' : 'إرسال'}
                            </button>
                        </div>
                    </div>
                )}



                        
            </div>
        );
    }
}
