import React, { Component , createRef} from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import Cart from './cart';
import logo from '../../assets/logo.png';
import line from '../../assets/line.png';
import footerLine from '../../assets/footer-line.png';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Feedback from '../Feedback';





class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        selectSubCategory: [],
        items: [],
        slide: [],
        loadSlide: false,
        loadCat: false,
        load: false,
        listview: "large",
        cart: false,
    }

    async componentDidMount(){

        // Get Items
        let id = window.location.pathname.replace("/menu/", "");

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/ninetynine");
        this.setState({ categories: data }, ()=>{
            // this.setState({loadCat: true});
            this.getCategoryName(id);
        });

        

        {
            // Get Slideshows
            const {data} = await axios.get("https://manage.caviarmenu.com/api/slideshow/ninetynine");
            this.setState({ slide: data }, ()=>{
                this.setState({loadSlide: true});
            });
        }

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/ninetynine");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
            });
        }
        

        this.layoutDirection();

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        const { categories } = this.state;
    
        if (categories.length === 0) {
            console.error("Categories list is empty.");
            return;
        }
    
        const selectedCategory = categories.find(cat => cat.id === id);
    
        if (selectedCategory) {
            this.setState({
                selectCategory: selectedCategory,
                selectSubCategory: selectedCategory.subCategories[0],
                loadCat: true,
            });
        } else {
            // Fallback to the first category if no match is found
            this.setState({
                selectCategory: categories[0],
                selectSubCategory: categories[0].subCategories[0],
                loadCat: true,
            });
        }
    }
    

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    // handleCategory = async catId => {
    //     this.setState({load: false});
    //     this.getCategoryName(catId);
    //     this.setState({load: true});
    // }

    handleListview = style => {
        this.setState({listview: style});
    }

    handleCartClose = close => {
        this.setState({cart: close});
    }

       constructor(props) {
        super(props);
        this.categoryRefs = {}; // Store refs for each category
        this.categoryContainer = createRef(); // Ref for the container
        this.state = {
          loadCat: true,
          categories: [], // Populate with your category data
          selectCategory: {}, // Track the selected category
        };
      }

    
      scrollToCategory = (categoryId) => {
        const categoryRef = this.categoryRefs[categoryId];
        if (categoryRef && categoryRef.current && this.categoryContainer.current) {
          const container = this.categoryContainer.current;
          const startPosition = container.scrollLeft;
          
        

               
          let targetPosition = 0;
         let distance = 0;
    if(this.props.Language == "en")
        {
              // Calculate position relative to the container, not the entire page
              targetPosition = categoryRef.current.offsetLeft - container.offsetLeft;
              distance = targetPosition - startPosition;
        }
    else
        {// Calculate target position from the right side of the container
         targetPosition = categoryRef.current.offsetLeft + categoryRef.current.offsetWidth - container.clientWidth;
         distance = targetPosition - startPosition;}
      
          const duration = 1500; // Duration in ms for slower scrolling (1.5 seconds)
          let startTime = null;
      
          const animateScroll = (currentTime) => {
            if (!startTime) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const scrollProgress = Math.min(timeElapsed / duration, 1);
      
            // Cubic ease-in-out function for smooth scroll
            const ease = scrollProgress < 0.5
              ? 4 * scrollProgress * scrollProgress * scrollProgress
              : 1 - Math.pow(-2 * scrollProgress + 2, 3) / 2;
      
            container.scrollLeft = startPosition + distance * ease;
      
            if (timeElapsed < duration) {
              requestAnimationFrame(animateScroll);
            }
          };
      
          requestAnimationFrame(animateScroll);
        }
      };
    
      componentDidUpdate(prevProps, prevState) {
        // Scroll to the active category whenever it changes
        if (prevState.selectCategory.id !== this.state.selectCategory.id) {
          this.scrollToCategory(this.state.selectCategory.id);
        }
      }
    
      // Method to handle category selection
      handleCategory = (category) => {
        this.setState({ selectSubCategory: category });
      };
      

      priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }
      

    render() {

        const isLtr = (this.props.Language == "en");

        return (
            <div className='menu-items' onScroll={this.handleScroll}>
                <div className="head ani-ttb-f" style={this.headCover} >


                    <div className="head-right">
                    
                    <Link to={"/home"} className='d-flex '>
                    <img src={logo} alt="" className='head-logo head-m-c ani-stb-f'/>
                    </Link>

                        <div 
                            className='head-btn feedback-btn'
                            style={ {'fontFamily':'Exo 2'}}>
                                <Link to={"/feedback"}>
                                    <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.51316 6.16182C8.51316 5.94438 8.33685 5.76807 8.11941 5.76807H3.78816C3.57072 5.76807 3.39441 5.94438 3.39441 6.16182C3.39441 6.37925 3.57072 6.55557 3.78816 6.55557H8.11941C8.33685 6.55557 8.51316 6.37925 8.51316 6.16182Z" fill="#FFFEF0" fillOpacity="0.8"/>
                                        <path d="M3.78724 7.75647C3.56981 7.75647 3.39349 7.93278 3.39349 8.15022C3.39349 8.36766 3.56981 8.54397 3.78724 8.54397H6.08368C6.30112 8.54397 6.47743 8.36766 6.47743 8.15022C6.47743 7.93278 6.30112 7.75647 6.08368 7.75647H3.78724Z" fill="#FFFEF0" fillOpacity="0.8"/>
                                        <path d="M11.1075 6.18364C10.8901 6.18364 10.7138 6.35996 10.7138 6.57739V9.76196C10.7138 10.3578 10.229 10.8426 9.63314 10.8426H6.99939C6.91407 10.8426 6.83095 10.8701 6.7627 10.9218L4.48857 12.632C4.43082 12.6753 4.37657 12.6573 4.34945 12.6425C4.32189 12.6285 4.27639 12.5939 4.27814 12.5235L4.32189 11.2503C4.32539 11.1436 4.28557 11.0399 4.21164 10.9633C4.1377 10.8863 4.03532 10.843 3.92857 10.843H2.09939C1.50351 10.843 1.01876 10.3587 1.01876 9.76239V4.55614C1.01876 3.95808 1.50351 3.47158 2.09939 3.47158H6.95126C7.1687 3.47158 7.34501 3.29527 7.34501 3.07783C7.34501 2.86039 7.1687 2.68408 6.95126 2.68408H2.09939C1.06951 2.68408 0.231262 3.52408 0.231262 4.55658V9.76239C0.231262 10.7923 1.06951 11.631 2.09939 11.631H3.52082L3.49107 12.4981C3.48057 12.8542 3.67001 13.178 3.98632 13.342C4.12151 13.4125 4.2672 13.447 4.41157 13.447C4.60539 13.447 4.79745 13.3845 4.96107 13.2624L7.13064 11.631H9.6327C10.6626 11.631 11.5008 10.7927 11.5008 9.76239V6.57783C11.5013 6.35996 11.3249 6.18364 11.1075 6.18364Z" fill="#FFFEF0" fillOpacity="0.8"/>
                                        <path d="M13.2359 2.43297C13.1646 2.21422 12.9892 2.05059 12.7665 1.99547L11.7603 1.74478L11.2108 0.865403C10.9671 0.474278 10.3309 0.475153 10.0877 0.865403L9.53819 1.74478L8.53194 1.99547C8.30925 2.05059 8.13338 2.21422 8.0625 2.43297C7.99163 2.65172 8.03713 2.88753 8.185 3.06384L8.85175 3.85747L8.77913 4.89172C8.76294 5.12097 8.86444 5.3384 9.05038 5.47359C9.16588 5.55759 9.3015 5.6009 9.43888 5.6009C9.52244 5.6009 9.60688 5.58472 9.68782 5.5519L10.6494 5.1634L11.6106 5.5519C11.8237 5.63853 12.0617 5.60878 12.2481 5.47403C12.434 5.33884 12.5355 5.1214 12.5198 4.89172L12.4471 3.85747L13.1139 3.0634C13.2613 2.88753 13.3073 2.65172 13.2359 2.43297ZM11.7414 3.4729C11.6758 3.55122 11.643 3.65184 11.6504 3.75422L11.7204 4.74734L10.7973 4.37415C10.7501 4.3549 10.6998 4.34528 10.6499 4.34528C10.6 4.34528 10.5497 4.3549 10.5024 4.37415L9.57975 4.74734L9.64932 3.75422C9.65632 3.65228 9.6235 3.55122 9.55832 3.4729L8.91825 2.71078L9.88425 2.46972C9.98313 2.44478 10.0689 2.38309 10.1227 2.29647L10.6503 1.45253L11.1779 2.29647C11.2322 2.38309 11.3175 2.44522 11.4164 2.46972L12.3824 2.71078L11.7414 3.4729Z" fill="#FFFEF0" fillOpacity="0.8"/>
                                    </svg>
                                    {
                                        this.props.Language == "en" ? 'Feedback'
                                        : this.props.Language == "krd" ? 'فیدباك'
                                        : 'فیدباك'
                                    } 
                                </Link>
                        </div>
                    </div>

                    <div className="head-left">

                        <div 
                                className='head-btn order-btn' 
                                onClick={()=> this.handleCartClose(true)}>
                                    <span className='cartQty'> { this.props.SumQty } </span> 
                                <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.2146 4.70302H11.7999L9.83082 0.98375C9.72458 0.78307 9.47582 0.70651 9.27506 0.812757C9.07438 0.919004 8.99782 1.16785 9.10406 1.36852L10.8694 4.70302H3.12817L4.89355 1.36852C4.9998 1.16785 4.92324 0.919004 4.72256 0.812757C4.52193 0.706537 4.27304 0.78307 4.16679 0.98375L2.19769 4.70302H0.785421C0.352347 4.70302 0 5.05537 0 5.48844V6.40182C0 6.81667 0.323346 7.1572 0.731256 7.18516L2.11066 12.9201C2.15509 13.1049 2.32038 13.2351 2.51043 13.2351H11.4869C11.6768 13.2351 11.8421 13.105 11.8866 12.9203L13.2692 7.1851C13.6769 7.15692 14 6.8165 14 6.40182V5.48844C14 5.05537 13.6477 4.70302 13.2146 4.70302ZM0.822344 5.52537H13.1777V6.36487H0.822344V5.52537ZM11.8924 9.3875H10.0921L10.3669 7.18721H12.4228L11.8924 9.3875ZM7.41173 12.4128V10.2098H9.16072L8.88568 12.4128H7.41173ZM5.11536 12.4128L4.84007 10.2098H6.58939V12.4128H5.11536ZM1.57756 7.18721H3.63364L3.9086 9.3875H2.10679L1.57756 7.18721ZM4.46234 7.18721H6.58939V9.3875H4.73731L4.46234 7.18721ZM7.41173 9.3875V7.18721H9.53812L9.26341 9.3875H7.41173ZM2.30456 10.2098H4.01134L4.28663 12.4128H2.83443L2.30456 10.2098ZM11.163 12.4128H9.71441L9.98945 10.2098H11.6941L11.163 12.4128Z" fill="#F7F3E9"/>
                                </svg>
                        </div>

                        <div className="language">
                                                <div 
                                                        className='head-btn language-active' 
                                                        // style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}
                                                        onClick={ () => this.setState({show: true}) }>
                                                        <i class="fa-solid fa-bars" ></i>

                                                            
                                                </div>
                                            
                                            </div>


                    </div>



                    </div>  
                <div className={`langModal ${this.state.show?'langModalShow':''}`}>
                                                    <div className='langModalContainer'>

                                                        <div className='langModalHead'>
                                                        <i className="fa-solid fa-xmark" role='button' onClick={ () => this.setState({show: false}) }></i>
                                                        </div>
                                                        <Link to={"/home"} className='d-flex '>
                                                        <img src={logo} alt="" className='head-logo'/>
                                                        </Link>
                                                        
                                                        <div className='langModalBody'>
                                                                    

                                                        <div className="accordion langauge-accordion" id="accordionExample">
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header">
                                                                <button
                                                                    className="accordion-button"
                                                                    type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne"
                                                                    aria-expanded="true"
                                                                    aria-controls="collapseOne"
                                                                >
                                                                    Languages
                                                                </button>
                                                            </h2>
                                                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    <div className='langModalBody'>
                                                                        <button
                                                                            type='submit'
                                                                            className={`${this.props.Language === "en" ? 'language-select-active' : ''}`}
                                                                            onClick={() => {
                                                                                this.handleChange('en');
                                                                                this.setState({ show: false });
                                                                            }}
                                                                        >
                                                                            English
                                                                        </button>

                                                                        <button
                                                                            type='submit'
                                                                            className={`${this.props.Language === "krd" ? 'language-select-active' : ''}`}
                                                                            onClick={() => {
                                                                                this.handleChange('krd');
                                                                                this.setState({ show: false });
                                                                            }}
                                                                            style={{fontFamily: 'Noto Kufi Arabic'}}
                                                                        >
                                                                            کوردی
                                                                        </button>

                                                                        <button
                                                                            type='submit'
                                                                            className={`${this.props.Language === "ar" ? 'language-select-active' : ''}`}
                                                                            onClick={() => {
                                                                                this.handleChange('ar');
                                                                                this.setState({ show: false });
                                                                            }}
                                                                            style={{fontFamily: 'Noto Kufi Arabic'}}
                                                                        >
                                                                            عربي
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                                                
                                                        </div>
                                                    </div>


                                                
                                </div>



                                <div 
                                className={this.props.Language == 'en' ? 'carousel ani-rtl' : 'carousel ani-ltr'}>
                    
                    {
                        this.state.loadSlide 
                        ? 
                            <Carousel autoPlay={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true}>
                                { this.state.slide.map(slide=> <div key={slide.id}><img src={slide.image}/></div>)}
                            </Carousel>
                        : 
                        <SkeletonTheme  baseColor="#1b0e01" highlightColor="#25341f">
                            <Skeleton height={90} 
                             customHighlightBackground="linear-gradient(90deg, #456 40%, #852 50%, #13A 60%)"/>
                        </SkeletonTheme>
                    }
                    
                             </div>
                

                <img src={line} alt="" className='line'/>

                <div className="categories ani-fade-in" ref={this.categoryContainer}
                style={this.props.Language !== 'en'? {direction: 'rtl'}: {direction: 'ltr'}}
                >
        {this.state.loadCat ? (
          <>
            {this.state.selectCategory.subCategories?.map((cat) => {
              if (!this.categoryRefs[cat.id]) {
                this.categoryRefs[cat.id] = createRef();
              }
              return (
                <div 
                  key={cat.id}
                  ref={this.categoryRefs[cat.id]}
                  onClick={() => this.handleCategory(cat)}
                className={this.props.Language === 'en' ? 'urbanist-font' : 'Noto Kufi Arabic-font'}

                >
                  <Category
                    Data={cat}
                    Language={this.props.Language}
                    handleCategory={this.handleCategory}
                    Active={this.state.selectSubCategory.id === cat.id}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <SkeletonTheme baseColor="#FFFFFF12" highlightColor="black" className='SkeletonTheme1'>
            <Skeleton height={50} 
            customHighlightBackground="linear-gradient(90deg, #456 40%, #852 50%, #13A 60%)"
            />
          </SkeletonTheme>
        )}
                 </div>

                <div className='items ani-btt' id='itemContainer'
                style={this.props.Language !== 'en'? {direction: 'rtl'}: {direction: 'ltr'}}>


                {
                    this.state.load ?

                    <PhotoProvider maskOpacity={0.7} photoClassName={'photoProviderImg'} overlayRender={ ({ images, index }) => {

                        if(!images[index]){
                            return null;
                        }
                        const data = images[index].overlay;                                                                
                                                    
                        return (
                            
                            <div className={`item-photo-view`} style={ {'direction': isLtr? "ltr" : "rtl"} }>
                                         

                            <div >
                                <h5 style={ this.props.Language == "en"? {'fontFamily':'Farro'}: {}}>
                                    {
                                        this.props.Language == "en" ? data.name_eng
                                        : this.props.Language == "krd" ? data.name_krd
                                        : data.name_ar
                                    }          
                                </h5>
                            </div>

                            

                            <div className='price'>
                                {
                                    (Array.isArray(data.prices) && data.prices.length)? 
                                    data.prices.map(
                                            (item, index ) => 
                                                <span key={index}> 
                                                    {
                                                        this.props.Language == "en" ? item.name_en
                                                        : this.props.Language == "krd" ? item.name_krd
                                                        : this.props.Language == "tr" ? item.name_tr
                                                        : item.name_ar
                                                    }
                                                    <span className='mx-2'>{ item.price } {(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'}</span>
                                                </span> )
                                    :
                                    <span> {data.price} {(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'} </span> 
                                }
                            </div>      

                           

                          
                            
                        </div>
                        );
                }} >
                        {this.state.loadCat ?
                        <div className='style-large'> 
                            { 
                                this.state.items[this.state.selectSubCategory.id]?.map((item, index) => 
                                    <Large key={item.id}
                                            Data = {item}
                                            Cart={this.props.Cart}
                                            Language = {this.props.Language} 
                                            addCartItem = {this.props.addCartItem} 
                                        ></Large> ) 
                            }
                        </div> : ''
                        }
                       
                    </PhotoProvider>
                    : 
                    <SkeletonTheme baseColor="#FFFFFF12" highlightColor="black"  >
                        <Skeleton count={5} height={200} className='SkeletonTheme1'
                        customHighlightBackground="linear-gradient(180deg, #456 40%, #852 50%, #13A 60%)"/>
                    </SkeletonTheme>
                }
                </div>

                <Cart Cart={this.props.Cart}
                        SumPrice={this.props.SumPrice}
                        SumQty={this.props.SumQty}
                        Language = {this.props.Language} 
                        Show = {this.state.cart}
                        clearCartItem = {this.props.clearCartItem}
                        updateCartItem = {this.props.updateCartItem}
                        HandleCartClose = {this.handleCartClose} />
                

                {/* <div className="bottom-d ani-btt">
                    <img src={footerLine} alt="" className='footerLine'/>
                </div> */}
                
            </div>
        );
    }
}

export default Menu;