import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import logo from '../../assets/logo.png';
import Ellipse from '../../assets/Ellipse.png';
class Intro extends Component {

    constructor(props) {
        super(props);
        
    }
    
    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        navigate: false,
        isLoading: false
    }
    
    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ isLoading: false });
    //     }, 2000);
    // }


    handleSubmit = event => {
        event.preventDefault();
        this.setState({ navigate : true});
    };
    
    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
    }

    render() {
        console.log(this.settings);
        const { isLoading } = this.state;

        return (
            <>

           
            
                { this.state.navigate && <Navigate to="/category" replace={true} /> }

                {
                    
                    isLoading ? 
                    <div className='intro'>
                            <div className="head">
                           
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="black" >
                                    <Skeleton height={150} width={150} circle={true} containerClassName="flex-1" />
                                </SkeletonTheme>
                            </div>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="black" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="black" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>
                        
                    </div>
                    : 
                    <div className='intro' style={ { backgroundImage :`url(${this.settings.introcover})`, backgroundSize: 'cover', backgroundOrigin: 'bottom' } } >
                    <div className="head ani-stb-f">
                        <img src={logo} alt="" className='' />
                        {/* <h3>{this.settings.name}</h3> */}
                        <h3 className="intro-tittle"
                         style={this.props.Language === "en" ? { fontFamily: 'Cochin' } : {}}>
                         {
                             this.props.Language == "en" ? (<>
                             Feel the luxuries <br/> taste.
                             </>)
                             : this.props.Language == "krd" ? 'حەژێکەرێ قەهوێ'
                             : 'عِش الحب مع'
                         } 
                        

                        </h3>
                    </div>
                    

                    <div className='t-b-intro'>
                    
                    <form onSubmit={this.handleSubmit} style={
                        this.props.language == !'en' ?{fontFamily: 'Noto Kufi Arabic'}: {fontFamily: 'Farro'}}>
                        <button className='English-button ani-btt-ss' type='submit' onClick={() => this.handleChange('en')} style={{fontFamily: 'Donegal One'}}> English </button>
                        <button type='submit' className='ani-btt-ss2' onClick={() => this.handleChange('krd')} style={{ fontFamily: 'Noto Kufi Arabic' }}> کوردی </button>
                        <button type='submit' className='ani-btt-ss3' onClick={() => this.handleChange('ar')} style={{ fontFamily: 'Noto Kufi Arabic' }}> عربی </button>
                        
                    </form>

                    
                    </div>

                   
                    <div className="social ani-fade-in">
                        { 
                            this.settings.instgram 
                            && 
                            <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                        }
                        { 
                            this.settings.tiktok 
                            && 
                            <a href={this.settings.tiktok}><i className="fa-brands fa-tiktok"></i></a>
                        }
                        { 
                            this.settings.snapchat 
                            && 
                            <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                        }
                        { 
                            this.settings.facebook 
                            && 
                            <a href={this.settings.facebook}><i className="fa-brands fa-facebook-f"></i></a>
                        }
                    </div>

                  
                </div>
    
                }

               
            </>
        );
    }
}

export default Intro;